import React, { useEffect, useState } from "react";
// import jQuery from "jquery";
import axios from "axios";
import "../css/draftAgreement.css";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getNotification, } from "../../Redux/Action/action";
import DatePicker from "react-multi-date-picker";
import UserService from "../../services/UserService";
import FooterOthers from './FooterOthers';
import Loader from "react-js-loader";
import {
  noteforusers,
  formdata,
  fieldNames,
} from "../Agreement_Data/Exemptionfrompa";
import NoteForUser from "./NoteForUser";

const { REACT_APP_CREATE_AGREEMENT_API, REACT_APP_REDIRECT_URI } = process.env;

function ExemptionApplication() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [checkBoxString, setCheckBoxString] = useState([]);
  // const [clause, setClause] = useState(true);
  const [createAgreement, setCreatAgreement] = useState([]);
  const [contentState, setContentState] = useState(true);
  const [handleSubmit, setHandleSubmit] = useState(false);
  const [loaderAgreement, setLoaderAgreement] = useState(false);
  const [loaderDownload, setLoaderDownload] = useState(false);
  const [loaderCredit, setLoaderCredit] = useState(false);
  const [promt, setPromt] = useState(true);
  const [contentViolationPromt, setContentViolationPromt] = useState(false)


  // const [other, setOther] = useState(false);
  // const [fileName, setFileName] = useState("");
  const [rename, setRename] = useState(false);
  const [renameString, setRenameString] = useState("New File");
  const [tagStore, setTagStore] = useState([]);

  const [checkmultiplehearing, setcheckmultiplehearing] = useState(false);
  const [joke, setJoke] = useState({
    setup: "",
    punchline: "",
  });
  const [checkresidence_same_as_hearing_place, setcheckresidence_same_as_hearing_place,] = useState(false);

  const [companyQuestion, setCompanyQuestion] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    // if (UserService.isLoggedIn()) getPlanDetails()
    getDataFromStorage();
    resetButtonView();
    if (UserService.isLoggedIn()) getDataFromLibrary();
  }, []);

  useEffect(() => {
    const handleKeyPress = (event) => {
      saveDatatoStorage();
    };
    document.addEventListener("input", handleKeyPress);
    document.addEventListener("click", handleKeyPress);

    return () => {
      document.removeEventListener("input", handleKeyPress);
      document.removeEventListener("click", handleKeyPress);
    };
  }, [tagStore]);
  const getDataFromLibrary = () => {
    if (location.state?.data) {
      const outputJSONfromLibrary = location.state?.data;
      // setOutputDetailsDataFromLibrary(location.state?.data)
      setRenameString(outputJSONfromLibrary.file_name);
      if (
        outputJSONfromLibrary.file_tags &&
        outputJSONfromLibrary.file_tags != null &&
        outputJSONfromLibrary.file_tags != "null"
      )
        setTagStore(outputJSONfromLibrary.file_tags.split(";"));

      fieldNames &&
        fieldNames.forEach((fieldName) => {
          const field = document.getElementById(fieldName);
          if (field) {
            field.value =
              outputJSONfromLibrary.api_input.tipsytom_input[fieldName];
          } else {
            return "";
          }
        });
      if (outputJSONfromLibrary.api_input.tipsytom_input.exempt_hearings) {
        if (outputJSONfromLibrary.api_input.tipsytom_input.exempt_hearings === "Every Hearing") {
          document.getElementById("EveryHearing").checked = true
        }
        else {
          setcheckmultiplehearing(true);
          document.getElementById("SelectDates").checked = true
          // const datePickerElement = document.getElementById("INPUT_EXEMPTIONDATES");
          // if (datePickerElement) {
          //   datePickerElement.value = outputJSONfromLibrary.api_input.tipsytom_input.exempt_hearings;
          // }
        }
      }
      if (
        document.getElementById("residence_same_as_hearing_place").value ===
        "No"
      ) {
        setcheckresidence_same_as_hearing_place(false);
      } else if (
        document.getElementById("residence_same_as_hearing_place").value ===
        "Yes"
      ) {
        setcheckresidence_same_as_hearing_place(true);
      }
    }
  };
  const handleContentEdit = () => {
    const updatedAgreement = [...createAgreement];
    updatedAgreement.map((item) => {
      updatedAgreement[item.serial] = {
        ...updatedAgreement[item.serial],
        content_text: document.getElementById(item.content_name).innerText,
      };
    });

    setCreatAgreement(updatedAgreement);
  };
  const handleConfirm = () => {
    setPromt(false)
  }
  const getDataFromStorage = () => {
    const masterStorageObject = JSON.parse(
      localStorage.getItem("masterExemptionPaApplicationObject")
    );
    if (masterStorageObject) {
      setRenameString(masterStorageObject.renameString);
      setTagStore(masterStorageObject.tags);
      setRenameString(masterStorageObject.renameString);
      setTagStore(masterStorageObject.tags);
      fieldNames.forEach((fieldName) => {
        const field = document.getElementById(fieldName);
        if (field) {
          field.value = masterStorageObject[fieldName] || "";
        }
      });

    }
  };
  const saveDatatoStorage = () => {
    const fileName = document.getElementById("input-file-name").value;
    const masterStorageObject = {};

    fieldNames &&
      fieldNames.forEach((fieldName) => {
        const field = document.getElementById(fieldName);
        const value = field ? field.value : "";
        masterStorageObject[fieldName] = value;
      });
    masterStorageObject.tags = tagStore;
    masterStorageObject.renameString = fileName;

    localStorage.setItem(
      "masterExemptionPaApplicationObject",
      JSON.stringify(masterStorageObject)
    );
  };
  const getJoke = async () => {
    try {
      const response = await axios.get(
        "https://official-joke-api.appspot.com/random_joke"
      );
      // console.log(response.data);
      setJoke({
        setup: response.data.setup,
        punchline: response.data.punchline,
      });
    } catch (err) {
      console.log("error", err);
    }
  };
  const resetButtonView = () => {
    const floatEnd = document.querySelectorAll(".float-end");
    if (floatEnd.length != 0 && window.innerWidth <= "600") {
      floatEnd.forEach((e) => {
        e.classList.remove("float-end");
      });
    }
  };
  const preventFromCopyPaste = () => {
    const edit = document.getElementById("edit");
    if (edit) {
      edit.addEventListener("copy", preventDefault);
      edit.addEventListener("cut", preventDefault);

      function preventDefault(e) {
        e.preventDefault();
      }
    }
  };

  const getScrrenTOLoader = () => {
    var element = document.getElementById("my-agreement-spinner");
    var offset = -200; // Adjust this value to set the desired offset
    if (element) {
      var elementPosition = element.getBoundingClientRect().top;
      var offsetPosition = elementPosition + window.pageYOffset + offset;

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth", // Optionally, you can add smooth scrolling animation
      });
    }
  };

  const handleWordLimit = (event, limit) => {
    const textareaValue = event.target.value;

    // Split the value into an array of words
    const words = textareaValue.split(/\s+/);

    // Remove any empty strings from the array
    const filteredWords = words.filter(word => word.length > 0);

    // Check if the number of words exceeds the limit
    if (filteredWords.length > limit) {
      // Truncate the value to the first 'limit' words
      const truncatedValue = filteredWords.slice(0, limit).join(' ');

      // Update the textarea value
      event.target.value = truncatedValue;

      // Allow one additional space after reaching the limit
      if (event.data === ' ' && event.inputType === 'insertText') {
        // Allow the space
      } else {
        // Prevent any additional input
        event.preventDefault();
      }
    }
  }

  const handleInputChange = (event) => {
    event.target.value = event.target.value.toUpperCase();
  };


  const sendData = async (value) => {
    setTimeout(() => {
      getScrrenTOLoader();
    }, 100);

    const court_name = document.getElementById("court_name").value;
    const accused_name = document.getElementById("accused_name").value;
    const case_number = document.getElementById("case_number").value;
    const accused_no = document.getElementById("accused_no").value;

    const gender_switch = () => {
      if (document.getElementById("gender_switch")) return document.getElementById("gender_switch").value;
      else return null
    };
    const place_of_hearing = document.getElementById("place_of_hearing").value;

    const residence_same_as_hearing_place = document.getElementById(
      "residence_same_as_hearing_place"
    ).value;
    const accused_residence_city = () => {
      if (document.getElementById("residence_same_as_hearing_place").value === "Yes") return place_of_hearing;
      else return document.getElementById("accused_residence_city").value || ""
    };
    const reasons_absence_1 = document.getElementById("reasons_absence_1").value;
    const reasons_absence_2 = document.getElementById("reasons_absence_2").value || null

    const supporting_doc_1 = document.getElementById("supporting_doc_1").value
    const supporting_doc_2 = document.getElementById("supporting_doc_2").value || null

    const date = document.getElementById("date").value;
    const advocate_name = document.getElementById("advocate_name").value;
    const advocate_city = document.getElementById("advocate_city").value;
    const complainant_name = document.getElementById("complainant_name").value;


    const exempt_hearings = () => {
      if (document.getElementById("EveryHearing").checked === true)
        return "Every Hearing";
      else if (
        document.getElementById("SelectDates").checked === true &&
        document.getElementById("INPUT_EXEMPTIONDATES")
      )
        return document.getElementById("INPUT_EXEMPTIONDATES").value;
      else {
        return "Every Hearing";
      }
    };


    const company_question = () => {
      if (document.getElementById("company_question_yes").checked === true) return "Yes";
      else if (document.getElementById("company_question_no").checked === true) return "No"
      else return "No"
    }

    const accused_pa_name = document.getElementById("accused_pa_name") ? document.getElementById("accused_pa_name").value : null
    const accused_pa_no = document.getElementById("accused_pa_name") ? document.getElementById("accused_pa_no").value : null
    const accused_relationship = document.getElementById("accused_pa_name") ? document.getElementById("accused_relationship").value : null



    let interval;
    getJoke();
    interval = setInterval(getJoke, 10000);
    let flag = false;
    if (companyQuestion) {
      if (accused_pa_name === "")
        dispatch(
          getNotification({
            message: "Please fill the name of the accused for whom the exemption from personal appearance is sought",
            type: "default",
          })
        );
      else if (accused_pa_no === "")
        dispatch(
          getNotification({
            message: "Please fill the accused no",
            type: "default",
          })
        );
      else if (accused_relationship === "")
        dispatch(
          getNotification({
            message: "Please fill the relationship of above accused with the primary accused",
            type: "default",
          })
        );
      else flag = true
    }
    else flag = true

    if (reasons_absence_1 === "")
      dispatch(
        getNotification({
          message: "Please fill main reasons for absence of the accused.",
          type: "default",
        })
      );

    else if (supporting_doc_1 === "")
      dispatch(
        getNotification({
          message: "Please fill supporting document (for main reason).",
          type: "default",
        })
      );

    else if (UserService.isLoggedIn()) {
      if (flag) {
        try {
          setLoaderAgreement(true);
          const FormData = require("form-data");
          const sendData = new FormData();
          sendData.append("keycloak_user_id", UserService.getUserId());
          sendData.append("agreement_type", "Exemption Application (Personal Appearance)");
          sendData.append("file_name", renameString);
          sendData.append(
            "file_tags",
            tagStore.join(",").replaceAll(",", ";").replace(/;$/, "")
          );
          sendData.append("api_type_alias", "Basic");

          sendData.append("accused_name", accused_name);
          sendData.append("accused_no", accused_no);
          sendData.append(
            "residence_same_as_hearing_place",
            residence_same_as_hearing_place
          );
          sendData.append("accused_residence_city", accused_residence_city());
          sendData.append("advocate_name", advocate_name);
          sendData.append("advocate_city", advocate_city);
          sendData.append("case_number", case_number);
          sendData.append("company_question", company_question());
          sendData.append("complainant_name", complainant_name);
          sendData.append("court_name", court_name);
          sendData.append("date", date);
          sendData.append("exempt_hearings", exempt_hearings());
          sendData.append("gender_switch", gender_switch());
          sendData.append("place_of_hearing", place_of_hearing);
          sendData.append("supporting_doc_1", supporting_doc_1);
          sendData.append("reasons_absence_1", reasons_absence_1);
          sendData.append("supporting_doc_2", supporting_doc_2);
          sendData.append("reasons_absence_2", reasons_absence_2);

          sendData.append("accused_pa_name", accused_pa_name)
          sendData.append("accused_pa_no", accused_pa_no);
          sendData.append("accused_relationship", accused_relationship);

          const config = {
            method: "post",
            url: `${REACT_APP_CREATE_AGREEMENT_API}/create_exception_personal_appearance_application`,
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              access_token: UserService.getToken(),
            },
            data: sendData,
          };
          setHandleSubmit(true);

          const getResponse = await axios(config);
          if (getResponse.status === 200) {
            const text = getResponse.data.agreement_text;
            setCreatAgreement(text);
            clearInterval(interval);
            // getPlanDetails("noRefresh");
            // console.log("Success");
          }
        } catch (err) {
          console.log(err);
          setHandleSubmit(false);
          setLoaderAgreement(false);
          clearInterval(interval);
          // console.log("Error", err);
          if (err.response && err.response.status === 503) {
            setContentViolationPromt(true)
          }
          else if (err.response && err.response.status === 401) {
            dispatch(
              getNotification({
                message: "Session expired! Please log in again",
                type: "default",
              })
            );
            setTimeout(() => {
              UserService.doLogin({
                redirectUri: `${REACT_APP_REDIRECT_URI}/app/exemption-application-from-pa`,
              });
            }, 3000);
          } else {
            dispatch(
              getNotification({
                message:
                  "We are experiencing high demand. Please try after a few minutes.",
                type: "default",
              })
            );
          }
        }
      }
    } else {
      dispatch(
        getNotification({
          message: "Session expired! Please log in again",
          type: "default",
        })
      );
      setTimeout(() => {
        UserService.doLogin({
          redirectUri: `${REACT_APP_REDIRECT_URI}/app/exemption-application-from-pa`,
        });
      }, 3000);
    }

    setLoaderAgreement(false);
    setTimeout(() => preventFromCopyPaste(), 100);
  };

  const download = async () => {
    const text = document.getElementById("edit").innerText;
    try {
      const obj = {
        keycloak_user_id: UserService.getUserId(),
        completions_dict: createAgreement,
      };

      var obj2 = JSON.stringify(obj);

      const apiUrl = `${REACT_APP_CREATE_AGREEMENT_API}/export_exemption_personal_appearance_docx`;
      const getResponse = await axios.post(apiUrl, obj2, {
        responseType: "blob",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          access_token: UserService.getToken(),
        },
      });
      setLoaderDownload(true);

      if (getResponse.status === 200) {
        const getCurrentDate = () => {
          const currentDate = new Date();
          const year = String(currentDate.getFullYear()).slice(-4);
          const month = String(currentDate.getMonth() + 1).padStart(2, "0");
          const day = String(currentDate.getDate()).padStart(2, "0");
          return `${year}-${day}-${month}`;
        };
        const blob = new Blob([getResponse.data], {
          type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = `Exemption_Personal_Appearance_Application_${getCurrentDate()}.docx`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        dispatch(
          getNotification({
            message: "Downloaded successfully",
            type: "success",
          })
        );
      }
    } catch (err) {
      setLoaderDownload(false);
      console.log("Error", err);
      dispatch(
        getNotification({
          message: "We are experiencing high demand. Please try after a few minutes.",
          type: "default",
        })
      );
    }
    setLoaderDownload(false);
    setTimeout(() => preventFromCopyPaste(), 10);
  };


  const resetForm = () => {
    fieldNames.forEach((fieldName) => {
      const field = document.getElementById(fieldName);
      if (field) {
        field.value = "";
      }
    });

    setRenameString("New File");
    let temp = [];
    setTagStore(temp);
  };

  const getTagButtonFromArray = () => {
    const tag = document.querySelector(".custom-input");
    let tempArr = [...tagStore];
    if (tag.value === "") {
      dispatch(
        getNotification({
          message: "Please enter a tag.",
          type: "default",
        })
      );
    } else {
      tempArr.push(tag.value);
    }
    setTagStore(tempArr);
    tag.value = "";
  };

  const removeTagFromArray = (value) => {
    let tempArr = [...tagStore];
    if (value) tempArr = tempArr.filter((e) => e !== value);
    setTagStore(tempArr);
  };

  return (
    <div id="contract" className="container-fluid contract">
      {loaderCredit ? (
        <div style={{ marginTop: "30vh" }} className="md:col-span-2 md:mt-0">
          <Loader
            type="bubble-spin"
            bgColor={"#000000"}
            title={"Loading..."}
            color={"#000000"}
            size={100}
          />
        </div>
      ) : (
        <div className="contractForm mt-1">
          <div>
            <div className="xl:grid xl:grid-cols-4 xl:gap-6">
              <div
                style={
                  {
                    // height: "1353px",
                  }
                }
                className={
                  handleSubmit ? "md:col-span-2" : "md:col-span-4 container"
                }
              >
                <div className="shadow sm:overflow-hidden sm:rounded-md mb-5">
                  <div
                    className={handleSubmit ? "d-none" : "row p-2"}
                    style={{
                      background:
                        "linear-gradient(to right, #D8E3F9, white,white)",
                    }}
                    role="alert"
                  >
                    <div className="col-sm">
                      <span className="bg-white text-primary px-3 py-1 tracking-widest text-xs absolute left-0 -top-2 rounded-bl">
                        Free
                      </span>
                      <h4 className="mx-5 text-primary">
                        Exemption Application
                      </h4>
                    </div>
                    <div className="col-sm items-center float-end">
                      <Link
                        to="/app"
                        spiketip-title="Go back to home page"
                        spiketip-pos="bottom"
                        className={`btn btn-outline-primary float-end mx-2 ${handleSubmit ? "mt-3" : ""
                          }`}
                      >
                        &#8592; Back
                      </Link>
                      <button
                        spiketip-title="Reset Form"
                        spiketip-pos="bottom"
                        onClick={() => {
                          resetForm();
                        }}
                        className={`btn btn-outline-dark float-end mx-2 ${handleSubmit ? "mt-3" : ""
                          }`}
                      >
                        <i className="fas fa-redo mr-2"></i>
                        Reset form
                      </button>
                    </div>
                  </div>
                  <hr className={handleSubmit ? 'mt-0 text-white' : 'mt-0'} />
                  <div className="row menu-bar mt-1 container-fluid">
                    <div className={handleSubmit ? "" : `col-sm px-2`}>
                      <button
                        className={`btn btn-light ml-3 ${handleSubmit ? "mb-2 w-5/6" : "w-full"
                          }`}
                      >
                        <input
                          id="input-file-name"
                          className={rename ? "float-start w-4/6" : "d-none"}
                          onKeyDown={(e) => {
                            if (e.key === "Enter") setRename(false);
                          }}
                          onChange={(e) => {
                            setRenameString(e.target.value);
                          }}
                          defaultValue={renameString}
                          type="text"
                          style={{
                            // width: "150px",
                            height: "30px",
                            color: "black",
                          }}
                        />
                        <span
                          onClick={() => {
                            if (rename) setRename(false);
                            else setRename(true);
                          }}
                          id="file-name"
                          spiketip-title={`${renameString}`}
                          spiketip-pos={
                            renameString.length > 40 ? "right" : "bottom"
                          }
                          className={
                            rename ? "d-none" : "float-start text-primary"
                          }
                        >
                          {renameString.length > 40
                            ? `${renameString.slice(0, 40)}...`
                            : renameString}
                        </span>
                        <span className="ml-2 float-end">
                          |
                          <i
                            onClick={() => {
                              if (rename) setRename(false);
                              else setRename(true);
                            }}
                            className={
                              rename ? "fas fa-check mx-3" : "fas fa-edit ml-3"
                            }
                          ></i>
                        </span>

                        {/* <i className="fas fa-times"></i> */}
                      </button>
                    </div>
                    <div className={handleSubmit ? "mx-4" : `col-sm`}>
                      <input
                        onKeyDown={(e) => {
                          if (e.keyCode === 13) {
                            getTagButtonFromArray(e.target.value);
                          }
                        }}
                        placeholder="Add tag here"
                        className={"px-1 mt-0.5 p-1 custom-input rounded-full"}
                        style={{
                          height: "35px",
                          // fontSize: handleSubmit ? "14px" : "",
                          width: "110px",
                          border: "2px solid #007BFF",
                          textAlign: "left",
                        }}
                        type="text"
                      />
                      <button
                        spiketip-title={"Add Tag"}
                        spiketip-pos="bottom"
                        onClick={() => getTagButtonFromArray()}
                        id="add-tag"
                        className="btn btn-outline-primary round-btn mx-2 -mt-1"
                      >
                        <i className="fas fa-plus plus-position"></i>
                      </button>

                      <span className="scrollable-span mx-1 mt-1 pb-1 w-4/6">
                        {tagStore.length > 0 &&
                          tagStore.map((ele, ind) => (
                            <button
                              style={{ borderRadius: "9999px" }}
                              key={ind}
                              className="btn btn-outline-dark p-1 px-2 px-1 position-relative"
                              onMouseEnter={(e) => {
                                const iconElement = e.target.querySelector("i");
                                iconElement &&
                                  iconElement.classList.remove("d-none");
                              }}
                              onMouseLeave={(e) => {
                                const iconElement = e.target.querySelector("i");
                                iconElement &&
                                  iconElement.classList.add("d-none");
                              }}
                            >
                              {ele}
                              <i
                                // style={{ display: "none" }}
                                onClick={() => {
                                  removeTagFromArray(ele);
                                }}
                                className="fas fa-window-close ml-1 d-none"
                              ></i>
                            </button>
                          ))}
                      </span>
                    </div>
                  </div>
                  <div className="space-y-6 bg-white sm:p-4">
                    <div className="grid grid-cols-3 gap-1 px-2">
                      {formdata &&
                        formdata.map((e, i) => (
                          <div key={i} className={"col-span-6 sm:col-span-3"}>
                            <label
                              htmlFor={e.id}
                              className={`block text-lg font-medium text-primary ${e.deactive ? "deactive-label" : ""
                                }`}
                            >
                              {e.name}{" "}
                              <span
                                className={`${e.required ? "text-danger" : "d-none"
                                  }`}
                              >
                                *
                              </span>
                            </label>
                            {e.group &&
                              e.group.map((ele, ind) => (
                                <div
                                  key={ind}
                                  className={`col-span-6 sm:col-span-3 px-1 my-2 ${ele.active ? "d-none" : ""
                                    }`}
                                >
                                  {ele.id !== "accused_residence_city" && (
                                    <label
                                      htmlFor={ele.id}
                                      className={`block text-sm font-medium text-black}`}
                                    >
                                      {ele.name}{" "}
                                      <span
                                        className={`${ele.required
                                          ? "text-danger"
                                          : "d-none"
                                          }`}
                                      >
                                        *
                                      </span>
                                    </label>
                                  )}
                                  {!checkresidence_same_as_hearing_place &&
                                    ele.id === "accused_residence_city" && (
                                      <label
                                        htmlFor={ele.id}
                                        className={`block text-sm font-medium text-black}`}
                                      >
                                        {ele.name}{" "}
                                        <span
                                          className={`${ele.required
                                            ? "text-danger"
                                            : "d-none"
                                            }`}
                                        >
                                          *
                                        </span>
                                      </label>
                                    )}
                                  {ele.type === "text" && ele.element === "input-textArea" &&
                                    <textarea
                                      rows="2"
                                      type={ele.type ? ele.type : "text"}
                                      placeholder={ele.placeholder}
                                      name={ele.nameAtt}
                                      id={ele.id}
                                      autoComplete="given-name"
                                      className={`${ele.type === "radio"
                                        ? "mt-1 block shadow-sm sm:text-sm"
                                        : "mt-1 block w-full rounded-md border-indigo-1000 shadow-sm focus:border-grren focus:ring-indigo-500 sm:text-sm"
                                        } ${ele.active
                                          ? "border-gray-300"
                                          : ""
                                        }`}
                                      disabled={ele.active}
                                    // onChange={(e) => {
                                    //   if (ele.id === "reasons_absence_1" || ele.id === "supporting_doc_1") handleWordLimit(e, 100)
                                    // }
                                    // }
                                    >
                                    </textarea>
                                  }
                                  {ele.type !== "input-select" &&
                                    ele.id !== "accused_residence_city" &&
                                    ele.element && ele.element !== "input-textArea" && (
                                      <input
                                        onChange={(e) => {
                                          if (ele.id === "court_name" || ele.id === "court_place") handleInputChange(e)
                                        }}
                                        type={ele.type ? ele.type : "text"}
                                        placeholder={ele.placeholder}
                                        name={ele.nameAtt}
                                        id={ele.id}
                                        autoComplete="given-name"
                                        className={`${ele.type === "radio"
                                          ? "mt-1 block shadow-sm sm:text-sm"
                                          : "mt-1 block w-full rounded-md border-indigo-1000 shadow-sm focus:border-grren focus:ring-indigo-500 sm:text-sm"
                                          } ${ele.active ? "border-gray-300" : ""
                                          }`}
                                        disabled={ele.active}
                                      />
                                    )}
                                  {ele.type === "input-select" && (
                                    <select
                                      id={ele.id}
                                      placeholder={ele.placeholder}
                                      className="mt-1 block w-full rounded-md border-indigo-1000 shadow-sm focus:border-grren focus:ring-indigo-500 sm:text-sm"
                                      onChange={(e) => {
                                        e.target.value === "No"
                                          ? setcheckresidence_same_as_hearing_place(
                                            false
                                          )
                                          : setcheckresidence_same_as_hearing_place(
                                            true
                                          );
                                      }}
                                    >
                                      {ele.group.map((ele2, index) => {
                                        return (
                                          <option key={index} id={ele2.id}>
                                            {ele2.name}
                                          </option>
                                        );
                                      })}
                                    </select>
                                  )}

                                  {ele.type !== "input-select" &&
                                    ele.id === "accused_residence_city" &&
                                    !checkresidence_same_as_hearing_place &&
                                    ele.element && (
                                      <input
                                        type={ele.type ? ele.type : "text"}
                                        placeholder={ele.placeholder}
                                        name={ele.nameAtt}
                                        id={ele.id}
                                        autoComplete="given-name"
                                        className={`${ele.type === "radio"
                                          ? "mt-1 block shadow-sm sm:text-sm"
                                          : "mt-1 block w-full rounded-md border-indigo-1000 shadow-sm focus:border-grren focus:ring-indigo-500 sm:text-sm"
                                          } ${ele.active ? "border-gray-300" : ""
                                          }`}
                                        disabled={ele.active}
                                      />
                                    )}
                                  {ele.group &&
                                    ele.group.map((ele2, ind2) => (
                                      <div
                                        key={ind2}
                                        className={`col-span-6 sm:col-span-3 ${ele2.type === "radio" ||
                                          ele2.type === "checkbox"
                                          ? "flex items-center"
                                          : ""
                                          } px-2 my-2`}
                                      >
                                        {ele2.type === "text" && (
                                          <label
                                            htmlFor={ele2.id}
                                            className={`block text-sm font-medium text-black ${ele2.active
                                              ? "text-secondary"
                                              : ""
                                              }`}
                                          >
                                            {ele2.name}{" "}
                                            <span
                                              className={`${ele2.required
                                                ? "text-danger"
                                                : "d-none"
                                                }`}
                                            >
                                              *
                                            </span>
                                          </label>
                                        )}
                                        {ele2.type !== "date-select" &&
                                          ele2.type !== "input-select" && (
                                            <input
                                              type={
                                                ele2.type ? ele2.type : "text"
                                              }
                                              placeholder={ele2.placeholder}
                                              name={ele2.nameAtt}
                                              id={ele2.id}
                                              disabled={
                                                ele2.disabled || ele2.active
                                              }
                                              autoComplete="given-name"
                                              className={`${ele2.type === "radio" ||
                                                ele2.type === "checkbox"
                                                ? "mb-1.5 block shadow-sm sm:text-sm mx-2"
                                                : "mt-1 block w-full rounded-md border-indigo-1000 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                                } ${ele2.disabled || ele2.active
                                                  ? "border-gray-300"
                                                  : ""
                                                }`}
                                              onChange={(event) => {
                                                const value = event.target.id;
                                                if (value === "SelectDates") {
                                                  setcheckmultiplehearing(true);
                                                } else {
                                                  setcheckmultiplehearing(
                                                    false
                                                  );
                                                }
                                              }}
                                            />
                                          )}
                                        {ele2.type === "date-select" &&
                                          checkmultiplehearing && (
                                            <div>
                                              <DatePicker
                                                inputClass="mt-1 block w-full rounded-md border-indigo-1000 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm "
                                                containerStyle={{
                                                  width: "100%",
                                                }}

                                                format="DD/MM/YYYY"
                                                id="INPUT_EXEMPTIONDATES"
                                                minDate={new Date()}
                                                multiple
                                                placeholder={ele2.placeholder}
                                              />
                                            </div>
                                          )}

                                        {ele2.type === "radio" && (
                                          <label
                                            htmlFor={ele2.id}
                                            className={`block text-sm font-medium text-black ${ele2.active
                                              ? "text-secondary"
                                              : ""
                                              }`}
                                          >
                                            {ele2.name}{" "}
                                            <span
                                              className={`${ele2.required
                                                ? "text-danger"
                                                : "d-none"
                                                }`}
                                            >
                                              *
                                            </span>

                                          </label>
                                        )}

                                        {ele2.type === "checkbox" && (
                                          <label
                                            htmlFor={ele2.id}
                                            className={`block text-sm font-medium text-black ${ele2.active
                                              ? "text-secondary"
                                              : ""
                                              }`}
                                          >
                                            {ele2.name}{" "}
                                            <span
                                              className={`${ele2.required
                                                ? "text-danger"
                                                : "d-none"
                                                }`}

                                            >
                                              *
                                            </span>
                                          </label>
                                        )}
                                      </div>

                                    ))}

                                  {/* specially for Exmption gender hide/unhide */}

                                  {ele.groupDiff &&
                                    ele.groupDiff.map((ele2, ind2) => (
                                      <div
                                        key={ind2}
                                        className={`col-span-6 sm:col-span-3 ${ele2.type === "radio" ||
                                          ele2.type === "checkbox"
                                          ? "flex items-center"
                                          : ""
                                          } px-2 my-2`}
                                      >
                                        {companyQuestion && ele2.type === "text" && (
                                          <label
                                            htmlFor={ele2.id}
                                            className={`block text-sm font-medium text-black ${ele2.active ? "text-secondary" : ""}`}
                                          >
                                            {ele2.name}{" "}
                                            <span
                                              className={`${ele2.required
                                                ? "text-danger"
                                                : "d-none"
                                                }`}
                                            >
                                              *
                                            </span>
                                          </label>
                                        )}
                                        {ele2.type === "radio" && (
                                          <input
                                            type={
                                              ele2.type ? ele2.type : "text"
                                            }
                                            placeholder={ele2.placeholder}
                                            name={ele2.nameAtt}
                                            id={ele2.id}
                                            disabled={
                                              ele2.disabled || ele2.active
                                            }
                                            autoComplete="given-name"
                                            className="mb-1.5 block shadow-sm sm:text-sm mx-2"
                                            onChange={() => {
                                              if (ele2.id === "company_question_yes") setCompanyQuestion(true)
                                              else if (ele2.id === "company_question_no") setCompanyQuestion(false)
                                            }}
                                          />
                                        )}
                                        {ele2.type === "input-select" && (
                                          <>
                                            <label
                                              htmlFor={ele2.id}
                                              className={`block text-sm font-medium text-black ${ele2.active ? "text-secondary" : ""}`}
                                            >
                                              {companyQuestion ? ele2.name2 : ele2.name}{" "}
                                              <span
                                                className={`${ele2.required
                                                  ? "text-danger"
                                                  : "d-none"
                                                  }`}
                                              >
                                                *
                                              </span>
                                            </label>
                                            <select
                                              id={ele2.id}
                                              placeholder={ele2.placeholder}
                                              className="mt-1 block w-full rounded-md border-indigo-1000 shadow-sm focus:border-grren focus:ring-indigo-500 sm:text-sm"
                                              onChange={(e) => {
                                                e.target.value === "No"
                                                  ? setcheckresidence_same_as_hearing_place(
                                                    false
                                                  )
                                                  : setcheckresidence_same_as_hearing_place(
                                                    true
                                                  );
                                              }}
                                            >
                                              {ele2.group.map((ele3, index) => {
                                                return (
                                                  <option key={index} id={ele3.id}>
                                                    {ele3.name}
                                                  </option>
                                                );
                                              })}
                                            </select>
                                          </>
                                        )}
                                        {companyQuestion && ele2.type === "text" && (
                                          <input
                                            type={
                                              ele2.type ? ele2.type : "text"
                                            }
                                            placeholder={ele2.placeholder}
                                            name={ele2.nameAtt}
                                            id={ele2.id}
                                            disabled={
                                              ele2.disabled || ele2.active
                                            }
                                            autoComplete="given-name"
                                            className="mt-1 block w-full rounded-md border-indigo-1000 shadow-sm focus:border-grren focus:ring-indigo-500 sm:text-sm"
                                          />
                                        )}

                                        {ele2.type === "radio" && (
                                          <label
                                            htmlFor={ele2.id}
                                            className={`block text-sm font-medium text-black ${ele2.active
                                              ? "text-secondary"
                                              : ""
                                              }`}
                                          >
                                            {ele2.name}{" "}
                                            <span
                                              className={`${ele2.required
                                                ? "text-danger"
                                                : "d-none"
                                                }`}
                                            >
                                              *
                                            </span>

                                          </label>
                                        )}
                                      </div>
                                    ))}
                                </div>
                              ))}
                          </div>
                        ))}
                    </div>
                    <div className="col-span-6 sm:col-span-3 text-center mx-2 pb-3">
                      {contentViolationPromt && (
                        <NoteForUser
                          note={"Some of your inputs are triggering content filters, please change your inputs."}
                          onConfirm={() => setContentViolationPromt(false)}
                          contentViolation={true}
                        />
                      )}
                      {promt && (
                        <NoteForUser
                          note={noteforusers}
                          onConfirm={handleConfirm}
                          link={true}
                        />
                      )}
                      <Link
                        to="/app"
                        spiketip-title="Submit Form"
                        spiketip-pos="top"
                        type="submit"
                        className="btn btn-outline-dark mt-1 block rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      >
                        Back
                      </Link>
                      <button
                        spiketip-title="Submit Form"
                        spiketip-pos="top"
                        type="submit"
                        className="btn btn-primary mt-1 block rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm mx-2 px-4"
                        onClick={() => {
                          sendData();
                        }}
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
                {/* </form> */}
              </div>
              {loaderAgreement ? (
                <div
                  id="my-agreement-spinner"
                  style={{ marginTop: "30vh" }}
                  className="md:col-span-2 md:mt-0"
                >
                  <Loader
                    type="bubble-ping"
                    bgColor={"#000000"}
                    title="It can take up to 30 seconds for TipsyTom to script."
                    color={"#000000"}
                    size={100}
                  />
                  <br />
                  <br />
                  {/* <h5 className={document.getElementById("toggle-button").classList.contains("active") ? "d-none" : "font-italic text-center mt-5"}> The strongest of all warriors are these two - time and patience</h5>
                                      <br />
                                      <figcaption className={document.getElementById("toggle-button").classList.contains("active") ? "d-none" : "blockquote-footer mb-0 font-italic text-end"}>
                                          Mark Twain
                                      </figcaption> */}

                  <h4 className="font-italic text-center mt-5">
                    {" "}
                    {joke.setup}
                  </h4>
                  <h5 className="font-italic text-center mt-2">
                    {" "}
                    {joke.punchline}
                  </h5>
                </div>
              ) : loaderDownload ? (
                <div
                  style={{ marginTop: "30vh" }}
                  className="md:col-span-2 md:mt-0"
                >
                  <Loader
                    type="box-rotate-z"
                    bgColor={"#000000"}
                    title={"Downloading.."}
                    color={"#000000"}
                    size={50}
                  />
                </div>
              ) : (
                <div
                  className={handleSubmit ? "md:col-span-2 md:mt-0" : "d-none"}
                >
                  <div className="sm:px-1 shadow sm:overflow-hidden sm:rounded-md py-1 px-1">
                    <div className="menu-bar">
                      <div
                        className="row p-2"
                        style={{
                          background:
                            "linear-gradient(to right, #D8E3F9, white,white)",
                        }}
                        role="alert"
                      >
                        <div className="col-sm">
                          <h5 className="ml-2 text-primary">
                            Exemption Application
                          </h5>
                        </div>
                        <div className="col-sm text-end">
                          <button className={contentState ? "d-none" : "btn btn-primary p-0 px-2 m-0"}>You are in edit mode.</button>
                          <button
                            spiketip-title={
                              contentState ? "Edit Content" : "Save Content"
                            }
                            spiketip-pos="bottom"
                            className={
                              UserService.isLoggedIn()
                                ? "btn btn-primary mx-4"
                                : "btn btn-secondary mx-4"
                            }
                            onClick={() => {
                              if (UserService.isLoggedIn()) {
                                setContentState(!contentState);
                                setTimeout(() => preventFromCopyPaste(), 10);
                                if (contentState) {
                                  document.getElementById(
                                    "edit"
                                  ).contentEditable = true;
                                  document
                                    .getElementById("download")
                                    .setAttribute("disabled", true);
                                  document
                                    .getElementById("downloadbutton2")
                                    .setAttribute("disabled", true);
                                } else {
                                  handleContentEdit();
                                  document.getElementById(
                                    "edit"
                                  ).contentEditable = false;
                                  document
                                    .getElementById("download")
                                    .removeAttribute("disabled");
                                  document
                                    .getElementById("downloadbutton2")
                                    .removeAttribute("disabled");
                                }
                              } else
                                UserService.doLogin({
                                  redirectUri:
                                    REACT_APP_REDIRECT_URI +
                                    "/app/ip-assignment-agreement",
                                });
                              // else dispatch(
                              //   getNotification({
                              //     message:
                              //       "Plesae sign up or log in to edit the document.",
                              //     type: "info",
                              //   })
                              // );
                            }}
                          >
                            <i
                              id="icon"
                              className={
                                contentState ? "fas fa-edit" : "fas fa-save"
                              }
                            ></i>
                          </button>
                          <button
                            spiketip-title="
                            
                            
                            
                            "
                            spiketip-pos="left"
                            id="download"
                            className={
                              UserService.isLoggedIn()
                                ? "btn btn-primary"
                                : "btn btn-secondary"
                            }
                            onClick={() => {
                              if (UserService.isLoggedIn()) download();
                              else
                                UserService.doLogin({
                                  redirectUri:
                                    REACT_APP_REDIRECT_URI +
                                    "/app/ip-assignment-agreement",
                                });
                              // else dispatch(
                              //   getNotification({
                              //     message:
                              //       "Plesae sign up or log in to download the document.",
                              //     type: "info",
                              //   })
                              // );
                            }}
                          >
                            <i className="fas fa-download"></i>
                          </button>
                        </div>
                      </div>
                      <hr className="mt-0" />
                    </div>
                    <div className="px-3">
                      <div
                        id="edit"
                        contentEditable={false}
                        style={{
                          height: (() => {
                            if (!companyQuestion && !checkmultiplehearing && checkresidence_same_as_hearing_place) return "1541px" //
                            else if (!companyQuestion && !checkmultiplehearing && !checkresidence_same_as_hearing_place) return "1613px"
                            else if (companyQuestion && !checkmultiplehearing && checkresidence_same_as_hearing_place) return "1763px"
                            else if (companyQuestion && !checkmultiplehearing && !checkresidence_same_as_hearing_place) return "1838px" //
                            else if (!companyQuestion && checkmultiplehearing && checkresidence_same_as_hearing_place) return "1591px"
                            else if (!companyQuestion && checkmultiplehearing && !checkresidence_same_as_hearing_place) return "1665px"
                            else if (companyQuestion && checkmultiplehearing && !checkresidence_same_as_hearing_place) return "1886px" //
                            else if (companyQuestion && checkmultiplehearing && checkresidence_same_as_hearing_place) return "1815px" //
                          })
                            ()
                          // !checkresidence_same_as_hearing_place
                          //   ? checkmultiplehearing
                          //     ? "1567px" //
                          //     : "1513px" //
                          //   : checkmultiplehearing
                          //     ? "1494px"
                          //     : "1442px", //
                          // overflow: "auto",
                        }}
                        className="mt-1 text-lg text-gray-600 whitespace-pre-wrap px-1"
                      >
                        {createAgreement?.map((item) => {
                          return (
                            <div key={item.serial} id={item.content_name}>
                              {item.content_text}
                            </div>
                          );
                        })}
                        <br />
                      </div>
                      <div className="text-center">
                        <button
                          id="downloadbutton2"
                          spiketip-title="Download Formatted Application"
                          spiketip-pos="top"
                          className={`btn btn-${UserService.isLoggedIn() ? "primary" : "secondary"
                            } mt-1 block rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm mb-3`}
                          onClick={() => {
                            if (UserService.isLoggedIn()) download();
                            else
                              UserService.doLogin({
                                redirectUri:
                                  REACT_APP_REDIRECT_URI +
                                  "/app/ip-assignment-agreement",
                              });
                            // else dispatch(
                            //   getNotification({
                            //     message:
                            //       "Plesae sign up or log in to download the document.",
                            //     type: "info",
                            //   })
                            // );
                          }}
                        >
                          Download <i className="fas fa-download mx-1"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <FooterOthers />
          </div>
        </div>
      )
      }
    </div >
  );
}

export default ExemptionApplication;