import React, { useEffect, useState } from "react";
// import jQuery from "jquery";
import axios from "axios";
import "../css/draftAgreement.css";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getNotification, } from "../../Redux/Action/action";
import Loader from "react-js-loader";
import FooterOthers from './FooterOthers';

import {
    noteforusers,
    formdata,
    fieldNames,
} from "../Agreement_Data/AnticipatoryBailApplication";
import NoteForUser from "./NoteForUser.jsx";
import UserService from "../../services/UserService";

const { REACT_APP_CREATE_AGREEMENT_API, REACT_APP_REDIRECT_URI } = process.env;

function AnticipatoryBailApplication() {
    const state = useSelector((state) => state.stateReducer);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    const [createAgreement, setCreatAgreement] = useState([]);
    const [contentState, setContentState] = useState(true);
    const [handleSubmit, setHandleSubmit] = useState(false);
    const [loaderAgreement, setLoaderAgreement] = useState(false);
    const [loaderDownload, setLoaderDownload] = useState(false);
    const [loaderCredit, setLoaderCredit] = useState(false);
    const [promt, setPromt] = useState(true);
    const [contentViolationPromt, setContentViolationPromt] = useState(false)

    // const [other, setOther] = useState(false);
    // const [fileName, setFileName] = useState("");
    const [rename, setRename] = useState(false);
    const [renameString, setRenameString] = useState("New File");
    const [tagStore, setTagStore] = useState([]);
    const [joke, setJoke] = useState({
        setup: "",
        punchline: "",
    });
    useEffect(() => {
        // if (UserService.isLoggedIn()) getPlanDetails()
        getDataFromStorage();
        resetButtonView();
        if (UserService.isLoggedIn()) getDataFromLibrary();
    }, []);
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    useEffect(() => {
        const handleKeyPress = (event) => {
            saveDatatoStorage();
        };
        document.addEventListener("input", handleKeyPress);
        document.addEventListener("click", handleKeyPress);

        return () => {
            document.removeEventListener("input", handleKeyPress);
            document.removeEventListener("click", handleKeyPress);
        };
    }, [tagStore]);

    const getDataFromLibrary = () => {
        if (location.state?.data) {
            const outputJSONfromLibrary = location.state?.data;
            // setOutputDetailsDataFromLibrary(location.state?.data)
            setRenameString(outputJSONfromLibrary.file_name);
            if (
                outputJSONfromLibrary.file_tags &&
                outputJSONfromLibrary.file_tags != null &&
                outputJSONfromLibrary.file_tags != "null"
            )
                setTagStore(outputJSONfromLibrary.file_tags.split(";"));

            fieldNames &&
                fieldNames.forEach((fieldName) => {
                    const field = document.getElementById(fieldName);
                    if (field) {
                        field.value =
                            outputJSONfromLibrary.api_input.tipsytom_input[fieldName];
                    } else {
                        return "";
                    }
                });

            if (
                outputJSONfromLibrary.api_input.tipsytom_input.criminal_antecedents === "Yes"
            ) {
                document.getElementById("criminal_antecedentsYes").checked = true;
            } else if (
                outputJSONfromLibrary.api_input.tipsytom_input.criminal_antecedents === "No"
            ) {

                document.getElementById("criminal_antecedentsNo").checked = true;

            }
        }
    };
    const handleConfirm = () => {
        setPromt(false)
    }

    const handleContentEdit = () => {
        const updatedAgreement = [...createAgreement];
        updatedAgreement.map((item) => {
            updatedAgreement[item.serial] = {
                ...updatedAgreement[item.serial],
                content_text: document.getElementById(item.content_name).innerText,
            };
        });

        setCreatAgreement(updatedAgreement);
    };

    const getDataFromStorage = () => {
        const masterStorageObject = JSON.parse(
            localStorage.getItem("masterAnticipatoryBailApplicationObject")
        );
        if (masterStorageObject) {
            setRenameString(masterStorageObject.renameString);
            setTagStore(masterStorageObject.tags);
            setRenameString(masterStorageObject.renameString);
            setTagStore(masterStorageObject.tags);
            fieldNames.forEach((fieldName) => {
                const field = document.getElementById(fieldName);
                if (field) {
                    field.value = masterStorageObject[fieldName] || "";
                }
            });

            // if (masterStorageObject.mode === "Pro") toggleSwitch()
        }
    };
    const saveDatatoStorage = () => {
        const fileName = document.getElementById("input-file-name").value;
        const masterStorageObject = {};

        fieldNames &&
            fieldNames.forEach((fieldName) => {
                const field = document.getElementById(fieldName);
                const value = field ? field.value : "";
                masterStorageObject[fieldName] = value;
            });
        masterStorageObject.tags = tagStore;
        masterStorageObject.renameString = fileName;

        localStorage.setItem(
            "masterAnticipatoryBailApplicationObject",
            JSON.stringify(masterStorageObject)
        );
    };

    const resetButtonView = () => {
        const floatEnd = document.querySelectorAll(".float-end");
        if (floatEnd.length != 0 && window.innerWidth <= "600") {
            floatEnd.forEach((e) => {
                e.classList.remove("float-end");
            });
        }
    };
    const preventFromCopyPaste = () => {
        const edit = document.getElementById("edit");
        if (edit) {
            edit.addEventListener("copy", preventDefault);
            edit.addEventListener("cut", preventDefault);

            function preventDefault(e) {
                e.preventDefault();
            }
        }
    };

    const getScrrenTOLoader = () => {
        var element = document.getElementById("my-agreement-spinner");
        var offset = -200; // Adjust this value to set the desired offset
        if (element) {
            var elementPosition = element.getBoundingClientRect().top;
            var offsetPosition = elementPosition + window.pageYOffset + offset;

            window.scrollTo({
                top: offsetPosition,
                behavior: "smooth", // Optionally, you can add smooth scrolling animation
            });
        }
    };
    const sendData = async (value) => {
        setTimeout(() => {
            getScrrenTOLoader();
        }, 100);

        const complainant_name = document.getElementById("complainant_name").value;

        const INPUT_GENDEROFCOMPLAINANT = () => {
            return document.getElementById("gender_complainant").value;
        };
        const petitioner_number =
            document.getElementById("petitioner_number").value;
        const complaint_date = document.getElementById("complaint_date").value;
        const criminal_case_number = document.getElementById(
            "criminal_case_number"
        ).value;
        const PS_Name = document.getElementById("PS_Name").value;
        const petitioner_name = document.getElementById("petitioner_name").value;
        const date_of_application = document.getElementById(
            "date_of_application"
        ).value;
        const petitioner_residence_city = document.getElementById(
            "petitioner_residence_city"
        ).value;
        const INPUT_gender_petitioner = () => {
            return document.getElementById("gender_petitioner").value;
        };
        const petitioner_profession_reputation = document.getElementById(
            "petitioner_profession_reputation"
        ).value;
        const petitioner_circumstances = document.getElementById(
            "petitioner_circumstances"
        ).value;
        const crim_section_number = "";
        const crim_offence_numbers = document.getElementById(
            "crim_offence_numbers"
        ).value;
        const seven_years_imprisonment = document.getElementById(
            "seven_years_imprisonment"
        ).value;

        const complaint_facts = document.getElementById("complaint_facts").value;
        const allegation_facts = document.getElementById("allegation_facts").value;
        const court_name = document.getElementById("court_name").value;
        const court_place = document.getElementById("court_place").value;
        const advocate_name = document.getElementById("advocate_name").value;
        const advocate_residence_city = document.getElementById(
            "advocate_residence_city"
        ).value;
        const respondent_name = document.getElementById("respondent_name").value;

        const criminal_antecedents = () => {
            if (document.getElementById("criminal_antecedentsYes").checked === true) return "Yes";
            else if (document.getElementById("criminal_antecedentsNo").checked === true) return "No";
            return "No"
        };

        let interval;
        getJoke();
        interval = setInterval(getJoke, 10000);

        if (
            crim_offence_numbers === "" ||
            complaint_facts === "" ||
            allegation_facts === ""
        ) {
            dispatch(
                getNotification({
                    message: "Please fill all the required fields marked with a red star.",
                    type: "default",
                })
            );
        } else {
            if (UserService.isLoggedIn()) {
                try {
                    setLoaderAgreement(true);
                    const FormData = require("form-data");
                    const sendData = new FormData();
                    sendData.append("keycloak_user_id", UserService.getUserId());
                    sendData.append("agreement_type", "anticipatory bail application");
                    sendData.append("file_name", renameString);
                    sendData.append(
                        "file_tags",
                        tagStore.join(",").replaceAll(",", ";").replace(/;$/, "")
                    );
                    sendData.append("api_type_alias", "Basic");
                    //section1
                    sendData.append("complainant_name", complainant_name);
                    sendData.append("gender_complainant", INPUT_GENDEROFCOMPLAINANT());
                    sendData.append("petitioner_number", petitioner_number);
                    sendData.append("complaint_date", complaint_date);
                    sendData.append("criminal_case_number", criminal_case_number);
                    sendData.append("PS_Name", PS_Name);
                    sendData.append("respondent_name", respondent_name);

                    //section 2
                    sendData.append("petitioner_name", petitioner_name);
                    sendData.append("date_of_application", date_of_application);
                    sendData.append(
                        "petitioner_residence_city",
                        petitioner_residence_city
                    );
                    sendData.append("gender_petitioner", INPUT_gender_petitioner());
                    sendData.append("criminal_antecedents", criminal_antecedents());
                    sendData.append(
                        "petitioner_profession_reputation",
                        petitioner_profession_reputation
                    );
                    sendData.append("petitioner_circumstances", petitioner_circumstances);

                    //section 3
                    sendData.append("crim_section_number", crim_section_number);
                    sendData.append("crim_offence_numbers", crim_offence_numbers);
                    sendData.append("crim_offences_sections", crim_offence_numbers);
                    sendData.append("seven_years_imprisonment", seven_years_imprisonment);
                    sendData.append("allegation_facts", allegation_facts);
                    sendData.append("complaint_facts", complaint_facts);

                    //section 4
                    sendData.append("court_name", court_name);
                    sendData.append("court_place", court_place);

                    //section5
                    sendData.append("advocate_name", advocate_name);
                    sendData.append("advocate_residence_city", advocate_residence_city);

                    sendData.append("reference_paragraph_facts", "");
                    sendData.append("gender_prompt_complainant", "");
                    sendData.append("gender_prompt_Applicant", "");
                    const config = {
                        method: "post",
                        url: `${REACT_APP_CREATE_AGREEMENT_API}/create_anticipatory_bail_application`,
                        headers: {
                            Accept: "application/json",
                            "Content-Type": "application/json",
                            access_token: UserService.getToken(),
                        },
                        data: sendData,
                    };
                    setHandleSubmit(true);

                    const getResponse = await axios(config);

                    if (getResponse.status === 200) {
                        clearInterval(interval);
                        const text = getResponse.data.agreement_text;
                        setCreatAgreement(text);

                        // getPlanDetails("noRefresh");
                        // console.log("Success");
                    }
                } catch (err) {
                    console.log(err);
                    clearInterval(interval);
                    setHandleSubmit(false);
                    setLoaderAgreement(false);
                    // console.log("Error", err);
                    if (err.response && err.response.status === 503) {
                        setContentViolationPromt(true)
                    }
                    else if (err.response && err.response.status === 401) {
                        dispatch(
                            getNotification({
                                message: "Session expired! Please log in again",
                                type: "default",
                            })
                        );
                        setTimeout(() => {
                            UserService.doLogin({
                                redirectUri: `${REACT_APP_REDIRECT_URI}/app/anticipatory-bail-application`,
                            });
                        }, 3000);
                    } else {
                        dispatch(
                            getNotification({
                                message:
                                    "We are experiencing high demand. Please try after a few minutes.",
                                type: "default",
                            })
                        );
                    }
                }
            } else {
                dispatch(
                    getNotification({
                        message: "Session expired! Please log in again",
                        type: "default",
                    })
                );
                setTimeout(() => {
                    UserService.doLogin({
                        redirectUri: `${REACT_APP_REDIRECT_URI}/app/anticipatory-bail-application`,
                    });
                }, 3000);
            }
        }

        setLoaderAgreement(false);
        setTimeout(() => preventFromCopyPaste(), 100);
    };

    const download = async () => {
        if (UserService.isLoggedIn()) {
            try {
                const court_place = document.getElementById("court_place").value;
                const petitioner_number =
                    document.getElementById("petitioner_number").value;

                const petitioner_name =
                    document.getElementById("petitioner_name").value;
                const complainant_name =
                    document.getElementById("complainant_name").value;
                const advocate_name = document.getElementById("advocate_name").value;
                const advocate_residence_city = document.getElementById(
                    "advocate_residence_city"
                ).value;

                const obj = {
                    keycloak_user_id: UserService.getUserId(),
                    completions_dict: createAgreement,
                    court_place: court_place,
                    petitioner_number: petitioner_number,
                    petitioner_name: petitioner_name,
                    complainant_name: complainant_name,
                    advocate_name: advocate_name,
                    advocate_residence_city: advocate_residence_city,
                };
                var obj2 = JSON.stringify(obj);

                const apiUrl = `${REACT_APP_CREATE_AGREEMENT_API}/export_anticipatory_bail_docx`;
                const getResponse = await axios.post(apiUrl, obj2, {
                    responseType: "blob",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        access_token: UserService.getToken(),
                    },
                });
                // const config = {
                //   method: "post",
                //   url: `${REACT_APP_CREATE_AGREEMENT_API}/export_anticipatory_bail_docx`,
                //   responseType: "blob",
                //   headers: {
                //     Accept: "application/json",
                //     "Content-Type": "application/json",
                //     access_token: UserService.getToken(),
                //   },
                //   data: sendData,
                // };
                setLoaderDownload(true);

                if (getResponse.status === 200) {
                    const getCurrentDate = () => {
                        const currentDate = new Date();
                        const year = String(currentDate.getFullYear()).slice(-4);
                        const month = String(currentDate.getMonth() + 1).padStart(2, "0");
                        const day = String(currentDate.getDate()).padStart(2, "0");
                        return `${year}-${day}-${month}`;
                    };
                    const blob = new Blob([getResponse.data], {
                        type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                    });
                    const url = window.URL.createObjectURL(blob);
                    const link = document.createElement("a");
                    link.href = url;
                    link.download = `Anticipatory_Bail_Application_${getCurrentDate()}.docx`;
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                    dispatch(
                        getNotification({
                            message: "Downloaded successfully",
                            type: "success",
                        })
                    );
                }
            } catch (err) {
                setLoaderDownload(false);
                console.log("Error", err);
                if (err.response && err.response.status === 401) {
                    dispatch(
                        getNotification({
                            message: "Session expired! Please log in again",
                            type: "default",
                        })
                    );
                    setTimeout(() => {
                        UserService.doLogin({
                            redirectUri: `${REACT_APP_REDIRECT_URI}/app/anticipatory-bail-application`,
                        });
                    }, 3000);
                } else {
                    dispatch(
                        getNotification({
                            message:
                                "We are experiencing high demand. Please try after a few minutes.",
                            type: "default",
                        })
                    );
                }
            }
            setLoaderDownload(false);
            setTimeout(() => preventFromCopyPaste(), 10);
        } else {
            dispatch(
                getNotification({
                    message: "Session expired! Please log in again",
                    type: "default",
                })
            );
            setTimeout(() => {
                UserService.doLogin({
                    redirectUri: `${REACT_APP_REDIRECT_URI}/app/anticipatory-bail-application`,
                });
            }, 3000);
        }
    };


    const resetForm = () => {
        fieldNames.forEach((fieldName) => {
            const field = document.getElementById(fieldName);
            if (field) {
                field.value = "";
            }
        });

        setRenameString("New File");
        let temp = [];
        setTagStore(temp);
    };


    const getJoke = async () => {
        try {
            const response = await axios.get(
                "https://official-joke-api.appspot.com/random_joke"
            );
            // console.log(response.data);
            setJoke({
                setup: response.data.setup,
                punchline: response.data.punchline,
            });
        } catch (err) {
            console.log("error", err);
        }
    };

    const getTagButtonFromArray = () => {
        const tag = document.querySelector(".custom-input");
        let tempArr = [...tagStore];
        if (tag.value === "") {
            dispatch(
                getNotification({
                    message: "Please enter a tag.",
                    type: "default",
                })
            );
        } else {
            tempArr.push(tag.value);
        }
        setTagStore(tempArr);
        tag.value = "";
    };

    const removeTagFromArray = (value) => {
        let tempArr = [...tagStore];
        if (value) tempArr = tempArr.filter((e) => e !== value);
        setTagStore(tempArr);
    };
    const handleWordLimit = (event, limit) => {
        const textareaValue = event.target.value;

        // Split the value into an array of words
        const words = textareaValue.split(/\s+/);

        // Remove any empty strings from the array
        const filteredWords = words.filter(word => word.length > 0);

        // Check if the number of words exceeds the limit
        if (filteredWords.length > limit) {
            // Truncate the value to the first 'limit' words
            const truncatedValue = filteredWords.slice(0, limit).join(' ');

            // Update the textarea value
            event.target.value = truncatedValue;

            // Allow one additional space after reaching the limit
            if (event.data === ' ' && event.inputType === 'insertText') {
                // Allow the space
            } else {
                // Prevent any additional input
                event.preventDefault();
            }
        }
    }
    const handleInputChange = (event) => {
        event.target.value = event.target.value.toUpperCase();
    };

    return (
        <div id="contract" className="container-fluid contract">
            {loaderCredit ? (
                <div style={{ marginTop: "30vh" }} className="md:col-span-2 md:mt-0">
                    <Loader
                        type="bubble-spin"
                        bgColor={"#000000"}
                        title={"Loading..."}
                        color={"#000000"}
                        size={100}
                    />
                </div>
            ) : (
                <div className="contractForm mt-1">
                    <div>
                        <div className="xl:grid xl:grid-cols-4 xl:gap-6">
                            <div
                                style={{
                                    // height: "1353px",
                                }}
                                className={
                                    handleSubmit ? "md:col-span-2" : "md:col-span-4 container"
                                }
                            >
                                <div className="shadow sm:overflow-hidden sm:rounded-md mb-5">
                                    <div className={handleSubmit ? "d-none" : "row p-2"} style={{ background: "linear-gradient(to right, #D8E3F9, white,white)" }} role="alert">
                                        <div className='col-sm'>
                                            <span className="bg-white text-primary px-3 py-1 tracking-widest text-xs absolute left-0 -top-2 rounded-bl">Free</span>
                                            <h4 className='mx-5 text-primary'>Anticipatory Bail Application</h4>
                                        </div>
                                        <div className='col-sm items-center float-end'>
                                            <Link
                                                to="/app"
                                                spiketip-title="Go back to home page" spiketip-pos="bottom"

                                                className={`btn btn-outline-primary float-end mx-2 ${handleSubmit ? "mt-3" : ""}`}
                                            >
                                                &#8592; Back
                                            </Link>
                                            <button
                                                spiketip-title="Reset Form" spiketip-pos="bottom"
                                                onClick={() => {
                                                    resetForm();
                                                }}
                                                className={`btn btn-outline-dark float-end mx-2 ${handleSubmit ? "mt-3" : ""}`}
                                            >
                                                <i className="fas fa-redo mr-2"></i>
                                                Reset form
                                            </button>
                                        </div>
                                    </div>
                                    <hr className={handleSubmit ? 'mt-0 text-white' : 'mt-0'} />
                                    <div className="row menu-bar mt-1 container-fluid">
                                        <div className={handleSubmit ? "" : `col-sm px-2`}>
                                            <button className={`btn btn-light ml-3 ${handleSubmit ? "mb-2 w-5/6" : "w-full"}`} >
                                                <input
                                                    id="input-file-name"
                                                    className={rename ? "float-start w-4/6" : "d-none"}
                                                    onKeyDown={(e) => {
                                                        if (e.key === "Enter") setRename(false);
                                                    }}
                                                    onChange={(e) => {
                                                        setRenameString(e.target.value);
                                                    }}
                                                    defaultValue={renameString}
                                                    type="text"
                                                    style={{
                                                        // width: "150px",
                                                        height: "30px",
                                                        color: "black",
                                                    }}
                                                />
                                                <span
                                                    onClick={() => {
                                                        if (rename) setRename(false);
                                                        else setRename(true);
                                                    }}
                                                    id="file-name" spiketip-title={`${renameString}`} spiketip-pos={renameString.length > 40 ? "right" : "bottom"}
                                                    className={rename ? "d-none" : "float-start text-primary"}>
                                                    {renameString.length > 40 ? `${renameString.slice(0, 40)}...` : renameString}
                                                </span>
                                                <span className="ml-2 float-end">
                                                    |
                                                    <i
                                                        onClick={() => {
                                                            if (rename) setRename(false);
                                                            else setRename(true);
                                                        }}
                                                        className={
                                                            rename ? "fas fa-check mx-3" : "fas fa-edit ml-3"
                                                        }
                                                    >
                                                    </i></span>

                                                {/* <i className="fas fa-times"></i> */}
                                            </button>
                                        </div>
                                        <div className={handleSubmit ? "mx-4" : `col-sm`}>
                                            <input
                                                onKeyDown={(e) => {
                                                    if (e.keyCode === 13) {
                                                        getTagButtonFromArray(e.target.value)
                                                    }
                                                }}
                                                placeholder="Add tag here"
                                                className={"px-1 mt-0.5 p-1 custom-input rounded-full"}
                                                style={{
                                                    height: "35px",
                                                    // fontSize: handleSubmit ? "14px" : "",
                                                    width: "110px",
                                                    border: "2px solid #007BFF",
                                                    textAlign: "left",
                                                }}
                                                type="text"
                                            />
                                            <button
                                                spiketip-title={"Add Tag"} spiketip-pos="bottom"
                                                onClick={() => getTagButtonFromArray()}
                                                id="add-tag"
                                                className="btn btn-outline-primary round-btn mx-2 -mt-1"
                                            >
                                                <i className="fas fa-plus plus-position"></i>
                                            </button>

                                            <span className="scrollable-span mx-1 mt-1 pb-1 w-4/6">
                                                {tagStore.length > 0 &&

                                                    tagStore.map((ele, ind) => (
                                                        <button
                                                            style={{ borderRadius: "9999px" }}
                                                            key={ind}
                                                            className="btn btn-outline-dark p-1 px-2 px-1 position-relative"
                                                            onMouseEnter={(e) => {
                                                                const iconElement = e.target.querySelector('i');
                                                                iconElement && iconElement.classList.remove("d-none")
                                                            }}
                                                            onMouseLeave={(e) => {
                                                                const iconElement = e.target.querySelector('i');
                                                                iconElement && iconElement.classList.add("d-none")
                                                            }}
                                                        >
                                                            {ele}
                                                            <i
                                                                // style={{ display: "none" }}
                                                                onClick={() => { removeTagFromArray(ele) }}
                                                                className="fas fa-window-close ml-1 d-none"></i>
                                                        </button>
                                                    ))}
                                            </span>
                                        </div>
                                    </div>
                                    <div className="space-y-6 bg-white sm:p-4">
                                        <div className="grid grid-cols-3 gap-1 px-2">
                                            {formdata &&
                                                formdata.map((e, i) => (
                                                    <div key={i} className={"col-span-6 sm:col-span-3"}>
                                                        <label
                                                            htmlFor={e.id}
                                                            className={`block text-lg font-medium text-primary ${e.deactive ? "deactive-label" : ""
                                                                }`}
                                                        >
                                                            {e.name}{" "}
                                                            <span
                                                                className={`${e.required ? "text-danger" : "d-none"
                                                                    }`}
                                                            >
                                                                *
                                                            </span>
                                                        </label>
                                                        {e.group &&
                                                            e.group.map((ele, ind) => (
                                                                <div
                                                                    key={ind}
                                                                    className={`col-span-6 sm:col-span-3 px-1 my-2 ${ele.active ? "d-none" : ""
                                                                        }`}
                                                                >
                                                                    <label
                                                                        htmlFor={ele.id}
                                                                        className={`block text-sm font-medium text-black}`}
                                                                    >
                                                                        {ele.name}{" "}
                                                                        <span
                                                                            className={`${ele.required ? "text-danger" : "d-none"
                                                                                }`}
                                                                        >
                                                                            *
                                                                        </span>
                                                                    </label>
                                                                    {ele.type === "text" && ele.element === "input-textArea" &&
                                                                        <textarea
                                                                            rows="5"
                                                                            type={ele.type ? ele.type : "text"}
                                                                            placeholder={ele.placeholder}
                                                                            name={ele.nameAtt}
                                                                            id={ele.id}
                                                                            autoComplete="given-name"
                                                                            className={`${ele.type === "radio"
                                                                                ? "mt-1 block shadow-sm sm:text-sm"
                                                                                : "mt-1 block w-full rounded-md border-indigo-1000 shadow-sm focus:border-grren focus:ring-indigo-500 sm:text-sm"
                                                                                } ${ele.active
                                                                                    ? "border-gray-300"
                                                                                    : ""
                                                                                }`}
                                                                            disabled={ele.active}
                                                                        // onChange={(e) => handleWordLimit(e, 500)}
                                                                        >
                                                                        </textarea>
                                                                    }
                                                                    {ele.type !== "input-select" && ele.element && ele.element !== "input-textArea" &&
                                                                        (
                                                                            <input
                                                                                onChange={(e) => {
                                                                                    if (ele.id === "court_name" || ele.id === "court_place") handleInputChange(e)
                                                                                }}
                                                                                type={ele.type ? ele.type : "text"}
                                                                                placeholder={ele.placeholder}
                                                                                name={ele.nameAtt}
                                                                                id={ele.id}
                                                                                autoComplete="given-name"
                                                                                className={`${ele.type === "radio"
                                                                                    ? "mt-1 block shadow-sm sm:text-sm"
                                                                                    : "mt-1 block w-full rounded-md border-indigo-1000 shadow-sm focus:border-grren focus:ring-indigo-500 sm:text-sm"
                                                                                    } ${ele.active
                                                                                        ? "border-gray-300"
                                                                                        : ""
                                                                                    }`}
                                                                                disabled={ele.active}
                                                                            />
                                                                        )}
                                                                    {ele.type === "input-select" && (
                                                                        <select
                                                                            id={ele.id}
                                                                            placeholder={ele.placeholder}
                                                                            className="mt-1 block w-full rounded-md border-indigo-1000 shadow-sm focus:border-grren focus:ring-indigo-500 sm:text-sm"
                                                                        >
                                                                            {ele.group.map((ele2, index) => {
                                                                                return (
                                                                                    <option id={ele2.id}>
                                                                                        {ele2.name}
                                                                                    </option>
                                                                                );
                                                                            })}
                                                                        </select>
                                                                    )}
                                                                    {ele.group &&
                                                                        ele.group.map((ele2, ind2) => (
                                                                            <div
                                                                                key={ind2}
                                                                                className={`col-span-6 sm:col-span-3 ${ele2.type === "radio" ||
                                                                                    ele2.type === "checkbox"
                                                                                    ? "flex items-center"
                                                                                    : ""
                                                                                    } px-2 my-2`}
                                                                            >
                                                                                {ele2.type === "text" && (
                                                                                    <label
                                                                                        htmlFor={ele2.id}
                                                                                        className={`block text-sm font-medium text-black ${ele2.active
                                                                                            ? "text-secondary"
                                                                                            : ""
                                                                                            }`}
                                                                                    >
                                                                                        {ele2.name}{" "}
                                                                                        <span
                                                                                            className={`${ele2.required
                                                                                                ? "text-danger"
                                                                                                : "d-none"
                                                                                                }`}
                                                                                        >
                                                                                            *
                                                                                        </span>
                                                                                    </label>
                                                                                )}
                                                                                {ele2.type !== "input-select" && (
                                                                                    <input
                                                                                        type={
                                                                                            ele2.type ? ele2.type : "text"
                                                                                        }
                                                                                        placeholder={ele2.placeholder}
                                                                                        name={ele2.nameAtt}
                                                                                        id={ele2.id}
                                                                                        disabled={
                                                                                            ele2.disabled ||
                                                                                            (ele2.active)
                                                                                        }
                                                                                        autoComplete="given-name"
                                                                                        className={`${ele2.type === "radio" ||
                                                                                            ele2.type === "checkbox"
                                                                                            ? "mb-1.5 block shadow-sm sm:text-sm mx-2"
                                                                                            : "mt-1 block w-full rounded-md border-indigo-1000 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                                                                            } ${ele2.disabled ||
                                                                                                (ele2.active)
                                                                                                ? "border-gray-300"
                                                                                                : ""
                                                                                            }`}
                                                                                        onChange={(event) => {
                                                                                            const value = event.target.id;
                                                                                        }}
                                                                                    />
                                                                                )}

                                                                                {ele2.type === "radio" && (
                                                                                    <label
                                                                                        htmlFor={ele2.id}
                                                                                        className={`block text-sm font-medium text-black ${ele2.active
                                                                                            ? "text-secondary"
                                                                                            : ""
                                                                                            }`}
                                                                                    >
                                                                                        {ele2.name}{" "}
                                                                                        <span
                                                                                            className={`${ele2.required
                                                                                                ? "text-danger"
                                                                                                : "d-none"
                                                                                                }`}
                                                                                        >
                                                                                            *
                                                                                        </span>
                                                                                    </label>
                                                                                )}
                                                                                {ele2.type === "checkbox" && (
                                                                                    <label
                                                                                        htmlFor={ele2.id}
                                                                                        className={`block text-sm font-medium text-black ${ele2.active
                                                                                            ? "text-secondary"
                                                                                            : ""
                                                                                            }`}
                                                                                    >
                                                                                        {ele2.name}{" "}
                                                                                        <span
                                                                                            className={`${ele2.required
                                                                                                ? "text-danger"
                                                                                                : "d-none"
                                                                                                }`}
                                                                                        >
                                                                                            *
                                                                                        </span>
                                                                                    </label>
                                                                                )}
                                                                            </div>
                                                                        ))}
                                                                </div>
                                                            ))}
                                                    </div>
                                                ))}
                                        </div>
                                        <div className="col-span-6 sm:col-span-3 text-center mx-2">
                                            {contentViolationPromt && (
                                                <NoteForUser
                                                    note={"Some of your inputs are triggering content filters, please change your inputs."}
                                                    onConfirm={() => setContentViolationPromt(false)}
                                                    contentViolation={true}
                                                />
                                            )}
                                            {promt && (
                                                <NoteForUser
                                                    note={noteforusers}
                                                    onConfirm={handleConfirm}
                                                />
                                            )}
                                            <Link
                                                to="/app"
                                                spiketip-title="Submit Form" spiketip-pos="top"
                                                type="submit"
                                                className="btn btn-outline-dark mt-1 mb-2 block rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                            >
                                                Back
                                            </Link>
                                            <button
                                                spiketip-title="Submit Form" spiketip-pos="top"
                                                type="submit"
                                                className="btn btn-primary mt-1 mb-2 block rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm mx-2 px-4"
                                                onClick={() => { sendData() }}
                                            >
                                                Submit
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                {/* </form> */}
                            </div>
                            {loaderAgreement ? (
                                <div
                                    id="my-agreement-spinner"
                                    style={{ marginTop: "30vh" }}
                                    className="md:col-span-2 md:mt-0"
                                >
                                    <Loader
                                        type="bubble-ping"
                                        bgColor={"#000000"}
                                        title="It can take up to 30 seconds for TipsyTom to script."
                                        color={"#000000"}
                                        size={100}
                                    />
                                    <br />
                                    <br />
                                    {/* <h5 className={document.getElementById("toggle-button").classList.contains("active") ? "d-none" : "font-italic text-center mt-5"}> The strongest of all warriors are these two - time and patience</h5>
                                    <br />
                                    <figcaption className={document.getElementById("toggle-button").classList.contains("active") ? "d-none" : "blockquote-footer mb-0 font-italic text-end"}>
                                        Mark Twain
                                    </figcaption> */}

                                    <h4 className="font-italic text-center mt-5"> {joke.setup}</h4>
                                    <h5 className="font-italic text-center mt-2"> {joke.punchline}</h5>
                                </div>
                            ) : loaderDownload ? (
                                <div
                                    style={{ marginTop: "30vh" }}
                                    className="md:col-span-2 md:mt-0"
                                >
                                    <Loader
                                        type="box-rotate-z"
                                        bgColor={"#000000"}
                                        title={"Downloading.."}
                                        color={"#000000"}
                                        size={50}
                                    />
                                </div>
                            ) : (
                                <div
                                    className={handleSubmit ? "md:col-span-2 md:mt-0" : "d-none"}
                                >
                                    <div className="sm:px-1 shadow sm:overflow-hidden sm:rounded-md py-1 px-1">
                                        <div className="menu-bar">
                                            <div className="row p-2" style={{ background: "linear-gradient(to right, #D8E3F9, white,white)" }} role="alert">
                                                <div className='col-sm'>
                                                    <h5 className='ml-2 text-primary'>Anticipatory Bail Application</h5>
                                                </div>
                                                <div className='col-sm text-end'>
                                                    <button className={contentState ? "d-none" : "btn btn-primary p-0 px-2 m-0"}>You are in edit mode.</button>
                                                    <button
                                                        spiketip-title={contentState ? "Edit Content" : "Save Conetent"} spiketip-pos="bottom"
                                                        className={UserService.isLoggedIn() ? "btn btn-primary mx-4" : "btn btn-secondary mx-4"}
                                                        onClick={() => {
                                                            if (UserService.isLoggedIn()) {
                                                                setContentState(!contentState);
                                                                setTimeout(() => preventFromCopyPaste(), 10);
                                                                if (contentState) {
                                                                    document.getElementById(
                                                                        "edit"
                                                                    ).contentEditable = true;
                                                                    document
                                                                        .getElementById("download")
                                                                        .setAttribute("disabled", true);
                                                                    document
                                                                        .getElementById("downloadbutton2")
                                                                        .setAttribute("disabled", true);
                                                                } else {
                                                                    handleContentEdit();
                                                                    document.getElementById(
                                                                        "edit"
                                                                    ).contentEditable = false;
                                                                    document
                                                                        .getElementById("download")
                                                                        .removeAttribute("disabled");
                                                                    document
                                                                        .getElementById("downloadbutton2")
                                                                        .removeAttribute("disabled");
                                                                }
                                                            }
                                                            else UserService.doLogin({ redirectUri: REACT_APP_REDIRECT_URI + "/app/ip-assignment-agreement" })
                                                            // else dispatch(
                                                            //   getNotification({
                                                            //     message:
                                                            //       "Plesae sign up or log in to edit the document.",
                                                            //     type: "info",
                                                            //   })
                                                            // );
                                                        }}
                                                    >
                                                        <i
                                                            id="icon"
                                                            className={
                                                                contentState ? "fas fa-edit" : "fas fa-save"
                                                            }
                                                        ></i>
                                                    </button>
                                                    <button
                                                        spiketip-title="Download Formatted Application" spiketip-pos="left"
                                                        id="download"
                                                        className={UserService.isLoggedIn() ? "btn btn-primary" : "btn btn-secondary"}
                                                        onClick={() => {
                                                            if (UserService.isLoggedIn()) download();
                                                            else UserService.doLogin({ redirectUri: REACT_APP_REDIRECT_URI + "/app/ip-assignment-agreement" })
                                                            // else dispatch(
                                                            //   getNotification({
                                                            //     message:
                                                            //       "Plesae sign up or log in to download the document.",
                                                            //     type: "info",
                                                            //   })
                                                            // );
                                                        }}
                                                    >
                                                        <i className="fas fa-download"></i>
                                                    </button>
                                                </div>
                                            </div>
                                            <hr className="mt-0" />
                                        </div>
                                        <div className="px-3">
                                            <div
                                                id="edit"
                                                contentEditable={false}
                                                style={{
                                                    height: "1955px",
                                                    overflow: "auto",
                                                }}
                                                className="mt-1 text-lg text-gray-600 whitespace-pre-wrap px-1"
                                            >
                                                {createAgreement?.map((item) => {
                                                    return (
                                                        <div

                                                            key={item.serial}
                                                            id={item.content_name}
                                                        >
                                                            {item.content_text}
                                                        </div>
                                                    );
                                                })}
                                                <br />
                                            </div>
                                            <div className='text-center'>
                                                <button
                                                    id="downloadbutton2"
                                                    spiketip-title="Download Formatted Application" spiketip-pos="top"
                                                    className={`btn btn-${UserService.isLoggedIn() ? "primary" : "secondary"} mt-1 block rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm mb-3`}
                                                    onClick={() => {
                                                        if (UserService.isLoggedIn()) download();
                                                        else UserService.doLogin({ redirectUri: REACT_APP_REDIRECT_URI + "/app/ip-assignment-agreement" })
                                                        // else dispatch(
                                                        //   getNotification({
                                                        //     message:
                                                        //       "Plesae sign up or log in to download the document.",
                                                        //     type: "info",
                                                        //   })
                                                        // );
                                                    }}
                                                >
                                                    Download{" "}
                                                    <i className="fas fa-download mx-1"></i>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                        <FooterOthers />
                    </div>
                </div>
            )}
        </div>
    );
}

export default AnticipatoryBailApplication;