import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getCheckPricing, getNotification, getUserDetails } from "../../Redux/Action/action";
import Loader from "react-js-loader";
import UserService from "../../services/UserService";
import "../css/mySubsctiptionDetails.css"
import mySubscriptionBG from "../../assets/mySubscriptionBG.png"

const { REACT_APP_CREATE_AGREEMENT_API, REACT_APP_REDIRECT_URI } = process.env;
const MySubscriptionDetails = () => {
    const state = useSelector((state) => state.stateReducer);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation()
    const [masterData, setmasterData] = useState([])
    const [userSubscriptionData, setUserSubscriptionData] = useState([])
    const [loader, setLoader] = useState(false)
    const [loader2, setLoader2] = useState(false)
    const [showCancellationModal, setShowCancellationModal] = useState(false);
    const [cancellationOptions, setCancellationOptions] = useState({})
    const [subID, setSubID] = useState("")
    const [cancleSuccess, setCancleSuccess] = useState(false)
    const [message, setMessage] = useState(false)
    const [freeUser, setFreeUser] = useState(false)
    const [current, setCurrent] = useState(true)
    const [hovered, setHovered] = useState(false);



    useEffect(() => {
        setUserStatus()
    }, [])

    const handleMouseEnter = () => {
        setHovered(true);
    };

    const handleMouseLeave = () => {
        setHovered(false);
    };


    const checkTimeOf1Hour = (value) => {
        const dateTimeString = value;
        const dateTime = new Date(dateTimeString);
        const targetDateTime = new Date(dateTime.getTime() + 60 * 60 * 1000); // Adding 1 hour in milliseconds
        const currentDateTime = new Date();
        if (currentDateTime >= targetDateTime) return 'over'
        else return 'not-over'

    }

    const setUserStatus = () => {
        const userID = UserService.getUserId()
        const userStatusObjFromStorage = JSON.parse(localStorage.getItem(userID + "StatusKeySubscriptions"))

        if (userStatusObjFromStorage && userStatusObjFromStorage.userStatusTime && userStatusObjFromStorage.userAPIResponse) {
            if (checkTimeOf1Hour(userStatusObjFromStorage.userStatusTime) === "not-over") {
                if (userStatusObjFromStorage.isActive)
                    setFreeUser(true)
                else {
                    setmasterData(userStatusObjFromStorage.userAPIResponse)
                    setUserSubscriptionData(userStatusObjFromStorage.subscriptionData)
                    setCancellationOptions(userStatusObjFromStorage.response)
                }
            }
            else {
                localStorage.removeItem(userID + "StatusKeySubscriptions");
                getListOfUserSubscription()
            }
        }
        else getListOfUserSubscription()
    }




    const getCurrent = () => {
        setCurrent(true)
        let temp = []
        temp = masterData
        temp = temp.filter(ele => ele.current_status === "active")
        setUserSubscriptionData(temp)
    }
    const getHistory = () => {
        setCurrent(false)
        let temp = []
        temp = masterData
        temp = temp.filter(ele => ele.current_status === "canceled")
        setUserSubscriptionData(temp)
    }


    const convertUTCToLocalTime = (utcTime) => {
        const date = new Date(utcTime);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');

        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    };


    const handleCancel = async () => {
        if (UserService.isLoggedIn()) {
            const cancleDetails = document.getElementById("cancellation-details").value
            const cancellationFeedback = document.getElementById("cancellation-feedback").value
            const cancellationComment = document.getElementById("cancellation-comment").value

            try {
                setLoader2(true);
                const FormData = require("form-data");
                const sendData = new FormData();

                sendData.append("keycloak_user_id", UserService.getUserId());
                sendData.append("stripe_subscription_id", subID);
                sendData.append("cancel_at_period_end", true);
                sendData.append("cancellation_details", cancleDetails);
                sendData.append("cancellation_feedback", cancellationFeedback);
                sendData.append("cancellation_comment", cancellationComment);
                sendData.append("pro_rata_refund_type", "remaining_time");

                const config = {
                    method: "post",
                    url: `${REACT_APP_CREATE_AGREEMENT_API}/cancel_subscription`,
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        "access_token": UserService.getToken()
                    },
                    data: sendData,
                };
                const getResponse = await axios(config);
                if (getResponse.status === 200) {
                    setCancleSuccess(true)
                    setMessage(getResponse.data.message)
                }
            } catch (err) {
                if (err.response && err.response.status === 401) {
                    dispatch(
                        getNotification({
                            message: "Session expired! Plesae log in again",
                            type: "default",
                        })
                    );
                    setTimeout(() => {
                        UserService.doLogin({ redirectUri: `${REACT_APP_REDIRECT_URI}/app/my-subscription` });
                    }, 3000);
                }
                else {
                    dispatch(
                        getNotification({
                            message: "Sorry, our network is down with too many requests. Please try in sometime!",
                            type: "default",
                        })
                    );
                }
            }
        } else {
            dispatch(
                getNotification({
                    message: "Session expired! Plesae log in again",
                    type: "default",
                })
            );
            setTimeout(() => {
                UserService.doLogin({ redirectUri: `${REACT_APP_REDIRECT_URI}/my-subscription` });
            }, 3000);
        }
        setLoader2(false);
    }



    const getListOfUserSubscription = async () => {
        if (UserService.isLoggedIn()) {
            try {
                setLoader(true);
                const FormData = require("form-data");
                const sendData = new FormData();
                sendData.append("keycloak_user_id", UserService.getUserId());

                const config = {
                    method: "post",
                    url: `${REACT_APP_CREATE_AGREEMENT_API}/list_active_user_subscriptions`,
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        "access_token": UserService.getToken()
                    },
                    data: sendData,
                };
                const getResponse = await axios(config);
                if (getResponse.status === 200) {
                    setmasterData(getResponse.data)
                    let temp = []
                    temp = getResponse.data
                    temp = temp.filter(ele => ele.current_status === "active")
                    setUserSubscriptionData(temp)
                    const resp = await fetch(`${REACT_APP_CREATE_AGREEMENT_API}/get_cancellation_feedback_options`)
                    const response = await resp.json()
                    setCancellationOptions(response)

                    const isCurrentAvailabe = (value) => {
                        let temp;
                        value.forEach((ele) => {
                            if (ele.current_status === "active") temp = true
                        })
                        if (temp === true) {
                            dispatch(getCheckPricing(false))
                            return true
                        }
                        else {
                            dispatch(getCheckPricing(true))
                            return false
                        }
                    }
                    const time = new Date()
                    const userID = UserService.getUserId()
                    const tempObj = {
                        userStatusTime: time,
                        userAPIResponse: getResponse.data,
                        subscriptionData: temp,
                        cancellationOptions: response,
                        navbarCheck: {
                            current: isCurrentAvailabe(getResponse.data)
                        }
                    }
                    localStorage.setItem(userID + "StatusKeySubscriptions", JSON.stringify(tempObj))
                }
            } catch (err) {
                console.log("Error", err);
                if (err.response && err.response.status === 400 && err.response.data.detail.message === "Either the UserID does not have any paid plan or it is an unknown UserID, please contact support for assistance if you think this is in error.") {
                    setFreeUser(true)

                    const time = new Date()
                    const userID = UserService.getUserId()
                    const tempObj = {
                        userStatusTime: time,
                        isActive: true,
                        navbarCheck: {
                            current: false
                        }
                    }
                    localStorage.setItem(userID + "StatusKeySubscriptions", JSON.stringify(tempObj))
                }
                else if (err.response && err.response.status === 401) {
                    dispatch(
                        getNotification({
                            message: "Session expired! Plesae log in again",
                            type: "default",
                        })
                    );
                    setTimeout(() => {
                        UserService.doLogin({ redirectUri: `${REACT_APP_REDIRECT_URI}/app/my-subscription` });
                    }, 3000);
                }
                else {
                    dispatch(
                        getNotification({
                            message: "Sorry, our network is down with too many requests. Please try in sometime!",
                            type: "default",
                        })
                    );
                }
            }
        } else {
            dispatch(
                getNotification({
                    message: "Session expired! Plesae log in again",
                    type: "default",
                })
            );
            setTimeout(() => {
                UserService.doLogin({ redirectUri: `${REACT_APP_REDIRECT_URI}/my-subscription` });
            }, 3000);
        }
        setLoader(false);
    }

    return (
        <div className='container-fluid bg-[#F9F9F9]'>
            <div className='container-fluid my-4 shadow-md card rounded-md'>
                {
                    freeUser ?
                        <div className="text-center py-2">
                            <p>You don't have any active subscriptions. Please subscribe to our plans.</p>
                            <Link className='btn btn-primary' to="/app/subscription">Subscribe</Link>
                        </div>
                        :
                        <div className="row bg-[#FBFCFF]">
                            <div className="col-sm px-5 sm:px-2 py-3">
                                <h1 className='mb-2'>My Subscriptions</h1>
                                <p className='text-secondary'>See what plan you’re on and your billing history here</p>
                            </div>
                            <div className="col-sm">
                                <div className="flex flex-col space-y-4 z-10">
                                    <div style={{ zIndex: "4" }} className="my-10 mx-auto border-2 border-indigo-500 rounded-md overflow-hidden">
                                        <button
                                            onClick={() => {
                                                getCurrent()
                                            }} className={current ? "py-1 px-4 btn-primary focus:outline-none" : "py-1 px-4 focus:outline-none"}>Current</button>
                                        <button
                                            onClick={() => {
                                                getHistory()
                                            }} className={current ? "py-1 px-4 focus:outline-none" : "py-1 px-4 btn-primary focus:outline-none"}>History</button>
                                    </div>
                                    <img style={{ zIndex: "1" }} className='absolute -top-3 right-0 h-full' src={mySubscriptionBG} alt="My Subscription BG" />
                                </div>
                            </div>
                        </div>
                }
            </div>
            {
                <div className={showCancellationModal ? "fixed left-0 top-0 flex h-full w-full items-center justify-center bg-black bg-opacity-50 py-10 p-3 z-10" : "d-none"}>
                    <div className={loader2 ? "loader-container-dark-theme" : "d-none"}>
                        <div className="loader-dark-theme"></div>
                    </div>
                    <div className="w-full mt-5 max-w-xl overflow-y-auto rounded-3xl bg-white relative p-5">
                        <div className={loader ? "loader-container-dark-theme" : "d-none"}>
                            <div className="loader-dark-theme"></div>
                        </div>
                        {
                            cancleSuccess ?
                                <div className="text-center">
                                    <h1 className="text-3xl font-semibold mb-4">Cancellation Request Received.</h1>
                                    <p className="mb-6">
                                        {message}
                                    </p>
                                    <Link
                                        to="/app"
                                        className="btn btn-primary"
                                    >
                                        Back to Home
                                    </Link>
                                </div>
                                :
                                <>
                                    <h2 className="text-2xl font-semibold text-primary">Cancel Subscription</h2>
                                    <p className='p-0 m-0 text-sm mb-2'>Your plan will be downgraded to Starter Plan after your subscription period ends</p>
                                    <div className="mb-4">
                                        <label className="block mb-2">
                                            Select a reason for cancellation:
                                        </label>
                                        <select
                                            id="cancellation-details"
                                            className="w-full px-3 py-2 rounded-md focus:outline-none focus:ring focus:border-blue-300"
                                        >
                                            <option selected disabled>--Select a reason--</option>
                                            {cancellationOptions && Object.entries(cancellationOptions).map(([key, value]) => (
                                                <option key={key} value={key}>{value}</option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="mb-4">
                                        <label className="block mb-2">Cancellation feedback:</label>
                                        <input
                                            type="text"
                                            id="cancellation-feedback"
                                            className="w-full px-3 py-2 rounded-md focus:outline-none focus:ring focus:border-blue-300"
                                        />
                                    </div>
                                    <div className="mb-4">
                                        <label className="block mb-2">Additional comments:</label>
                                        <textarea
                                            id="cancellation-comment"
                                            rows="4"
                                            className="w-full px-3 py-2 rounded-md focus:outline-none focus:ring focus:border-blue-300"
                                        />
                                    </div>
                                    <div className="flex justify-center space-x-6">
                                        <button
                                            className="btn btn-outline-primary font-semibold px-4 py-2 rounded-md"
                                            onClick={() => setShowCancellationModal(false)}
                                        >
                                            Cancel
                                        </button>
                                        <button
                                            className="btn btn-primary font-semibold px-4 py-2 rounded-md mr-2"
                                            onClick={() => handleCancel()}
                                        >
                                            Confirm
                                        </button>
                                    </div>
                                </>
                        }
                    </div>
                </div>
            }
            <div className="container-fluid">
                <div className="row mt-5">
                    <div className='shadow-md card p-2 text-center'>
                        <div className="overflow-x-auto">
                            <table className="table-auto w-full">
                                <thead>
                                    <tr>
                                        <th className="px-4 py-3">#</th>
                                        <th className="px-4 py-3">PLAN</th>
                                        <th className="px-4 py-3">AMOUNT</th>
                                        <th className="px-4 py-3">CURRENCY</th>
                                        <th className="px-4 py-3">START</th>
                                        <th className="px-4 py-3">END</th>
                                        <th className="px-4 py-3">STATUS</th>
                                        <th className="px-4 py-3">ACTION</th>
                                    </tr>
                                </thead>
                                <tbody className='bg-[#EEF8F2]'>
                                    {userSubscriptionData && userSubscriptionData.map((subscription, ind) => (
                                        <tr key={subscription.stripe_subscription_id}>
                                            <td className="px-4 py-3 ">{++ind}</td>
                                            <td className="px-4 py-3 ">{subscription.amount === 49 || subscription.amount === 3999 ? "Monthly" : "Annually"}</td>
                                            <td className="px-4 py-3 ">{subscription.currency_code === 'usd' && <i className="fas fa-dollar-sign mr-1"></i>}
                                                {subscription.currency_code === 'inr' && <i className="fas fa-rupee-sign mr-1"></i>}
                                                {subscription.amount}</td>
                                            <td className="px-4 py-3 ">{subscription.currency_code.toUpperCase()}</td>
                                            <td className="px-4 py-3 ">{convertUTCToLocalTime(subscription.current_period_start)}</td>
                                            <td className="px-4 py-3 ">{convertUTCToLocalTime(subscription.current_period_end)}</td>
                                            <td className="px-4 py-3 ">
                                                <span className='flex'>
                                                    {subscription.current_status.toUpperCase()}
                                                    <div className={`w-4 h-4 rounded-full ${subscription.current_status === "active" ? "bg-green-500" : "bg-red-500"}  ml-2 mt-1`}></div>
                                                </span>
                                            </td>
                                            <td className="px-4 py-3">
                                                <button
                                                    onMouseEnter={handleMouseEnter}
                                                    onMouseLeave={handleMouseLeave}
                                                    onClick={() => {
                                                        if (subscription.current_status === "active" && subscription.cancel_at_period_end) {
                                                            dispatch(
                                                                getNotification({
                                                                    message: "Your subscription has been cancelled. We are sorry to see you go!",
                                                                    type: "info",
                                                                })
                                                            );
                                                        }
                                                        else if (subscription.current_status === "active" && !subscription.cancel_at_period_end) {
                                                            setShowCancellationModal(true)
                                                            setSubID(subscription.stripe_subscription_id)
                                                        }
                                                    }}
                                                    className={subscription.cancel_at_period_end ? "btn btn-secondary" : "bg-green-600 hover:bg-red-700 text-white font-semibold px-4 py-2 rounded-md transition-colors duration-300 ease-in-out"}
                                                >
                                                    {subscription.current_status === "active" ? subscription.cancel_at_period_end === false ?
                                                        hovered
                                                            ? "Cancel Subscription"
                                                            : "Auto-renews on " + convertUTCToLocalTime(subscription.current_period_end).slice(0, 10) : "Auto-cancels on " + convertUTCToLocalTime(subscription.current_period_end).slice(0, 10)
                                                        : "Cancelled on " + convertUTCToLocalTime(subscription.current_period_end).slice(0, 10)
                                                    }
                                                </button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MySubscriptionDetails;
