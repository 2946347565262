import React, { useState } from 'react';
import "../css/popup.css"
import UserService from '../../services/UserService';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import falcon from "../../assets/falcon.png"
import owl from "../../assets/owl.png"


const { REACT_APP_REDIRECT_URI } = process.env
const Popup = ({ onConfirm, onCancel, onProConfirm, active }) => {
    const state = useSelector(state => state.stateReducer)
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [showPopup, setShowPopup] = useState(true);

    const handleConfirm = () => {
        setShowPopup(false);
        onConfirm();
    };

    const handleCancel = () => {
        setShowPopup(false);
        onCancel();
    };
    return (
        <>
            {showPopup && (
                <div className="popup-overlay alert alert-secondary" role="alert">
                    <div className="popup-container p-5 position-relative">
                        <i onClick={handleCancel} className='far fa-window-close fa-2x position-absolute top-2 right-5 cursor-pointer'></i>
                        {/* <div className={UserService.isLoggedIn() ? "d-none" : "popup-message"}>{message1}</div>
                        <div className="popup-message mb-4">{message2}</div> */}
                        <h4 className="text-primary float-start mb-2">Script the Agreement?</h4>
                        <br /><br />
                        <p className="popup-message my-2 text-secondary float-start">Before you proceed, please select the mode you would prefer.</p>
                        <br /><br />
                        <div className='row flex my-4'>
                            <div className="col-sm card rounded-cs mx-3" style={{ width: "22rem" }}>
                                <div className="card-body p-2">
                                    <p className="flex fw-bold text-primary text-lg my-2 mx-3">Falcon Mode
                                        <img src={falcon} alt="falcon" />
                                    </p>
                                    <ul className="px-4 text-start" style={{ listStyleType: "disc" }}>
                                        <li className="p-0 m-2">Draft swiftly and accurately.</li>
                                        <li className="p-0 m-2">Excels in most situations.</li>
                                        <li className="p-0 m-2">Precise and to the point.</li>
                                        <li className="p-0 m-2">Uses ChatGPT 3.5.</li>
                                        <div className='text-center'>
                                            <button onClick={handleConfirm} className="btn btn-primary my-3">Use Falcon Mode</button>
                                        </div>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-sm card mx-3" style={{ width: "22rem" }}>
                                <div className="card-body p-2">
                                    <p className="flex fw-bold text-primary text-lg my-2 mx-3">Owl Mode &nbsp;
                                        <img src={owl} alt="owl" />
                                    </p>
                                    <ul className="px-4 text-start w-full" style={{ listStyleType: "disc" }}>
                                        <li className="p-0 m-2">Slower but more considered.</li>
                                        <li className="p-0 m-2">Crafts complex drafts with depth.</li>
                                        <li className="p-0 m-2">More nuance.</li>
                                        <li className="p-0 m-2">Uses ChatGPT 4.0.</li>
                                        <div className='text-center'>
                                            <button onClick={() => {
                                                if (active) {
                                                    onProConfirm()
                                                }
                                                else {
                                                    if (!UserService.isLoggedIn()) UserService.doLogin({ redirectUri: REACT_APP_REDIRECT_URI + "/app/subscription" })
                                                    else if (UserService.isLoggedIn() && state.planDetails.user_subscription_summary && Object.entries(state.planDetails.user_subscription_summary).length) onProConfirm()
                                                    else navigate("/app/subscription")
                                                }
                                            }} className="btn btn-primary my-3">Use Owl Mode</button>
                                        </div>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        {/* <div className="popup-actions">
                            <button className='btn btn-danger' onClick={handleCancel}> <i className='fas fa-times mr-2'></i>{UserService.isLoggedIn() ? "Not yet, go back" : "Not yet"}</button>
                            <button className='btn btn-dark' onClick={handleConfirm}> <i className='fas fa-scroll mr-2'></i>{mode ? "Script in Falcon Mode" : "Continue with Falcon Mode"}</button>
                            <button className="btn btn-success" onClick={() => {
                                if (active) {
                                    onProConfirm()
                                }
                                else {
                                    if (!UserService.isLoggedIn()) UserService.doLogin({ redirectUri: REACT_APP_REDIRECT_URI + "/app/subscription" })
                                    else if (UserService.isLoggedIn() && state.planDetails.user_subscription_summary && Object.entries(state.planDetails.user_subscription_summary).length) onProConfirm()
                                    else navigate("/app/subscription")
                                }
                            }}><i className='fas fa-scroll mr-2'></i>{mode ? "Continue with Owl Mode" : "Script in Owl Mode"}</button>
                            <button className={UserService.isLoggedIn() ? "d-none" : 'btn btn-outline-info'} onClick={() => UserService.doLogin()}> <i className='fas fa-sign-in-alt mr-2'></i>Log in</button>
                            <button className={UserService.isLoggedIn() ? "d-none" : 'btn btn-info'} onClick={() => UserService.doSignUp()}> <i className='fas fa-user-plus mr-2'></i>Sign-me up!</button>
                             <button className={UserService.isLoggedIn() && state.planDetails.user_subscription_summary && !Object.entries(state.planDetails.user_subscription_summary).length ? "btn btn-dark" : 'd-none'} onClick={() => navigate("/app/subscription")} ><i className='fas fa-arrow-circle-up mr-2'></i>Subscribe for high quality drafts!</button>
                    </div>
                     */}
                    </div>
                </div >
            )}
        </>
    );
};

export default Popup;